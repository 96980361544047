import { DatePipe } from "@angular/common";
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "subscription_date",
  pure: true,
})
export class SubscriptionDatePipe implements PipeTransform {
  private datePipe = new DatePipe("en-US");

  transform(value: number | null): string | null {
    if (value === null) return "";
    const milliseconds = value * 1000;
    const fixedFormat = "y-MM-dd hh:mm";
    return this.datePipe.transform(milliseconds, fixedFormat);
  }
}
